import { createClient } from 'contentful'
import useSWR from 'swr'
import { Layout } from '../components'
import { Auth0Provider } from '../store/authStore/auth0';
import config from '../store/authStore/auth0.config';
import { useAuth0 } from '../store/authStore/authStore';
import { DOMAIN, LIFERAY_CONNECT_ENDPOINT } from '../config';
import {NotificationManager} from '@sky-tv-group/components';

const previewMode = process.env.REACT_APP_PREVIEW_MODE as string;
const spaceId = process.env.REACT_APP_CONTENTFUL_SPACE_ID as string
let deliveryToken = process.env.REACT_APP_CONTENTFUL_DELIVERY_TOKEN as string;

if (previewMode === 'preview') {
    deliveryToken = process.env.REACT_APP_CONTENTFUL_PREVIEW_TOKEN as string 
}

const environment = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT as string 
let host = process.env.REACT_APP_CONTENTFUL_CDN_HOST as string;

if (previewMode === 'preview') {
    host = process.env.REACT_APP_CONTENTFUL_PREVIEW_HOST as string;
}

const client = createClient({
    space: spaceId,
    accessToken: deliveryToken,
    environment: environment,
    host: host
})

const homeFetcher = async () => {
    const entryItems = await client.getEntries({ content_type: 'home', limit: 1, include: 10 })
    return entryItems.items[0].fields
}

const Home = () => {
    const onRedirectCallback = () => {
        window.history.replaceState({}, document.title, '/');
      };

    const { data } = useSWR('home', homeFetcher)

    function AuthenticatedApp() {
        const { loading } = useAuth0();
        return loading ? <Layout data={data}/> : <Layout data={data}/>;
      }

    if(data) {
        return (
          <>
          <NotificationManager notificationsEndpoint={LIFERAY_CONNECT_ENDPOINT} domain={DOMAIN} />
              <Auth0Provider
                config={{
                  domain: config.domain,
                  client_id: config.clientId,
                  redirect_uri: window.location.origin,
                  audience: config.audience,
                }}
                onRedirectCallback={onRedirectCallback}>
                <AuthenticatedApp/>
          </Auth0Provider>
        </>
        );
    }
    return null;
}

export default Home;